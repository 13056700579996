import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkout"
export default class extends Controller {
  static values = {
    shippingMethods: Array
  }
  static targets = [
    "billingAddressDiv",
    "billingAddressInputField",
    "shippingSelectCard",
    "shippingUnselectedError",
    "itemQuantity",
    "itemCost",
    "itemDeleteButton",
    "editCartButton",
    "saveCartButton",
    "subtotalCost",
    "taxCost",
    "shippingCost",
    "totalCost",
    "checkoutItemRow",
    "cartSavedMessage",
    "quantityZeroError",
    "checkoutSubmit",
    "deliveryCountry",
    "billingCountry"
  ]

  currentShippingMethod = '';
  cart_cookie_name = 'myalarm_store_cart';
  isCartEmpty = false;
  isSavedCartAnimating = false;

  connect() {
    this.calculateCost();
  }

  toggleSameAsDelivery(event) {
    if(event.target.checked) {
      this.billingAddressDivTarget.classList.add('hide');
      this.disableBillingAddressFields();

    } else {
      this.billingAddressDivTarget.classList.remove('hide');
      this.enableBillingAddressFields();
    }
  }

  disableBillingAddressFields() {
    // Sets the fields as disabled after a delay, so that they don't visually change to greyed-out until hidden.
    // Looks strange otherwise
    setTimeout(() => {
      this.billingAddressInputFieldTargets.forEach (field => {
        field.disabled = true;
      });
    }, 400); //400 milliseconds of delay
  }

  enableBillingAddressFields(){
    this.billingAddressInputFieldTargets.forEach (field => {
      field.disabled = false;
    });
  }

  validate(event) {
    // Check if user has selected shipping option
    let checked = this.shippingSelectCardTargets.some(shippingSelectCard => shippingSelectCard.checked);
    if (!checked) {
      event.preventDefault(); // Prevent form submission
      this.shippingUnselectedErrorTarget.classList.remove("d-none");
      this.shippingUnselectedErrorTarget.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      this.shippingUnselectedErrorTarget.classList.add("d-none");
    }

    // Prevent form submission if user has any items with quantity 0 in the cart
    if (this.isCartEmpty) {
      event.preventDefault(); // Prevent form submission
      this.quantityZeroErrorTarget.classList.remove("d-none");
      this.quantityZeroErrorTarget.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      this.quantityZeroErrorTarget.classList.add("d-none");
    }
  }

  updateQuantityInput(event) {
    var showSavedText = true;

    const maxLimit = 25;
    const quantityField = event.target;
  
    let value = quantityField.value;
  
    // This is a text field, so remove any character that is not a digit (0-9)
    value = value.replace(/[^\d]/g, '');

    // 3 Digit limit
    if (value.length > 3) {
      value = value.slice(0, 3);
      showSavedText = false;
    }
    let parsedValue = parseInt(value, 10);

    // This means if Not-a-Number (or if you delete all the numbers from the field) it defaults to 0.
    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }
    if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
      showSavedText = false;
    }
    if (parsedValue < 0){
      parsedValue = 0;
      showSavedText = false;
    } 
    quantityField.value = parsedValue;

    this.calculateCost();
    if (showSavedText) this.updateCart();
  }

  updateCart() {
    var cart = this.getCartFromCookie();
    if (!cart) return;

    this.isCartEmpty = false;

    this.itemQuantityTargets.forEach(field => {
      const currItemCode = field.dataset.itemCode;
      const selectedCartItem = cart.find(item => item.code == currItemCode);
      selectedCartItem.quantity = field.value;

      const quantity = (parseInt(selectedCartItem.quantity), 10);
      if (quantity < 1) {
        this.isCartEmpty = true;
      }
    });
    this.saveCartToCookie(cart);
    this.showCartSavedMessage();
  }

  selectShipping(event) {
    const shippingMethodId = event.target.id;
    var countryCode;
    var cost = 0;
    if (this.hasBillingCountryTarget && this.billingCountryTarget.value != '') {
      countryCode = this.billingCountryTarget.value;
    } else {
      countryCode = this.deliveryCountryTarget.value;
    }
    
    if (shippingMethodId === 'cardStandardShipping') {
      this.currentShippingMethod = 'standard';
    } else if (shippingMethodId === 'cardExpressShipping') {
      this.currentShippingMethod = 'express';
    }
    if (this.currentShippingMethod == '') {
      return
    }
    var method = this.shippingMethodsValue.find( sm => sm['method'] == this.currentShippingMethod && sm['country_code'] == countryCode);
    cost = method['cost']
    // This should work even if in NZD, as it's just formatting using a $ sign. 
    this.shippingCostTarget.textContent =
      `${(cost / 100).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}`;
    this.calculateCost();
    this.checkoutSubmitTarget.disabled = false;
  }

  calculateCost() {
    var total = 0.0;

    this.itemQuantityTargets.forEach(item => {

      const quantity = item.value;
      if (!quantity) {
        return;
      }

      const parts = item.name.split('][');
      const itemName = parts[0].replace('[', '');
      const itemPriceString = item.dataset.itemPrice;
      if (!itemPriceString) {
        return;
      }

      var itemCost = parseFloat(item.dataset.itemPrice, 10);
      const itemTotal = itemCost * quantity;
      total += itemTotal;

      const itemCostLabel = this.itemCostTargets.find(x => x.id.startsWith(itemName));
      if (!itemCostLabel) {
        return;
      }
      itemCostLabel.textContent = itemTotal.toLocaleString('en-AU', { 
        style: 'currency', 
        currency: 'AUD',
        maximumFractionDigits: 0  
      });
    });

    const subtotal = total;
    this.subtotalCostTarget.textContent = subtotal.toLocaleString('en-AU', { 
      style: 'currency', 
      currency: 'AUD',
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2  
    });

    const shippingCost = parseFloat(this.shippingCostTarget.textContent.trim().replace("$", ""));
    if (shippingCost){
      total += shippingCost;
    } 

    const taxPercentage = this.element.dataset.taxPercentage // This is provided by the ruby controller based on country
    const tax = total * taxPercentage; 
    this.taxCostTarget.textContent = tax.toLocaleString('en-AU', { 
      style: 'currency', 
      currency: 'AUD',
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2  
    });

    total += tax;
    this.totalCostTarget.textContent = total.toLocaleString('en-AU', { 
      style: 'currency', 
      currency: 'AUD',
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2  
    });
  }

  deleteButton(event) {
    var cart = this.getCartFromCookie();
    if (!cart) return;

    const currItemCode = event.currentTarget.dataset.itemCode;
    cart = cart.filter(item => item.code !== currItemCode);
    this.saveCartToCookie(cart);

    const itemIndex = event.currentTarget.dataset.itemIndex;
    const divToDelete = this.checkoutItemRowTargets.find(row => row.id === `${itemIndex}_row`);
    divToDelete.remove();
    this.calculateCost();
    this.showCartSavedMessage();
    event.preventDefault();
  }

  getIndexFromitemIndex(itemIndex) {
    parseInt(itemIndex.match(/\d+/)[0], 10) - 1;
  }

  getCartFromCookie() {
    const cookies = document.cookie.split('; ');
    for (let cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === this.cart_cookie_name) {
          const valueWithSpaces = value.replace(/\+/g, ' ');
          const cartData = JSON.parse(decodeURIComponent(valueWithSpaces));
          return cartData;
        }
    }
    return null;
  }

  saveCartToCookie(cart) {
    if (cart.length > 0) {
      const cartJson = JSON.stringify(cart);
      const encodedCart = encodeURIComponent(cartJson);
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 2);
      document.cookie = `${this.cart_cookie_name}=${encodedCart}; expires=${expiryDate.toUTCString()}; path=/`;
    } else {
      // If cart is empty, delete cookie and then reload page, which will redirect the user away from the cart.
      document.cookie = `${this.cart_cookie_name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      location.reload();
    }
  }

  restrictEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
    }
  }
  
  showCartSavedMessage() {
    if (this.isSavedCartAnimating) return;
  
    const message = this.cartSavedMessageTarget;
    this.isSavedCartAnimating = true;
  
    // Fade in
    message.classList.remove("opacity-0");
    message.classList.add("show");
  
    // Wait for 1 second before fading out
    setTimeout(() => {
      message.classList.remove("show");
      message.classList.add("opacity-0");
  
      setTimeout(() => {
        this.isSavedCartAnimating = false;
      }, 300);
    }, 1000); // Set how long the message lasts for in milliseconds
  }
}
