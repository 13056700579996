import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="panel-status-icon"
export default class extends Controller {
  static targets = ["iconCard"]

  connect() {
    this.conformStatusCardHeight();
  }

  conformStatusCardHeight() {
    var maxHeight = this.iconCardTargets.reduce(
      (max, currentIconCard) =>  Math.max(max, currentIconCard.offsetHeight), 0
    );

    this.iconCardTargets.forEach((iconCard) => {
      iconCard.style.height = `${maxHeight}px`;
    });
  }
}
