import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="personal-reporting"
export default class extends Controller {
  static values = {
    clientCountry: String,
    ruleType: String,
    ifobUnlimited: Boolean,
  }
  static targets = [
    "ruleType", "editRuleType",
    "deletePersonalReportingRuleModal",, "deletePersonalReportingRuleModalType",
    "deletePersonalReportingRuleModalAddress", "deletePersonalReportingRuleModalDeleteButton",
    "emailAddress", "emailAddressInput",
    "pushDeviceIndex",
    "phoneNumber", "smsRuleHelp", "voiceRuleHelp", "voiceRuleOptions", "voiceRuleTroubleOptions",
    "mobilePhoneSpan", "phoneSpan",
    "emergencyPhoneNumber", "emergencyVoiceCallAddress",
    "phone", "mobilePhone", "note", "submitForm",
    "zonesAdvancedButton",
    "alarmVoiceMessage", "alarmVoiceMessagePlayer",
    "troubleVoiceMessage", "troubleVoiceMessagePlayer"
  ]

  connect() {
    if (this.hasRuleTypeTarget) {
      this.setupReportingView(1, true)
      this.ruleTypeTarget.value = 1;
    }
  }

  editRuleTypeTargetConnected(target) {
    var selectedType = 0
    if (this.ruleTypeValue == 'email') {
      selectedType = 0
    } else if (this.ruleTypeValue == 'push') {
      selectedType = 1
    } else if (this.ruleTypeValue == 'sms') {
      selectedType = 2
    } else if (this.ruleTypeValue == 'voice') {
      selectedType = 3
    } else if (this.ruleTypeValue == 'emergency_voice') {
      selectedType = 4
    }
    this.editRuleTypeTarget.value = selectedType
    this.setupReportingView(selectedType, false)
  }

  playAlarmVoiceMessage(event) {
    var data = JSON.stringify({ test_voice: { message: this.alarmVoiceMessageTarget.value }, authenticity_token: document.querySelectorAll('meta[name="csrf-token"]')[0]['content'] })
    fetch(event.params['alarmEndpoint'], { method: 'post', headers: { 'Content-Type': 'application/json;charset=utf-8'}, body: data})
      .then(response => response.json())
      .then(responseData => {
        this.alarmVoiceMessagePlayerTarget.src = responseData['filename']
        this.alarmVoiceMessagePlayerTarget.load()
        this.alarmVoiceMessagePlayerTarget.pause()
        this.alarmVoiceMessagePlayerTarget.play()
        this.alarmVoiceMessagePlayerTarget.classList.remove('d-none')
      })
      .catch(err => {})
  }

  playTroubleVoiceMessage(event) {
    var data = JSON.stringify({ test_voice: { message: this.troubleVoiceMessageTarget.value }, authenticity_token: document.querySelectorAll('meta[name="csrf-token"]')[0]['content'] })
    fetch(event.params['testEndpoint'], { method: 'post', headers: { 'Content-Type': 'application/json;charset=utf-8'}, body: data})
      .then(response => response.json())
      .then(responseData => {
        this.troubleVoiceMessagePlayerTarget.src = responseData['filename']
        this.troubleVoiceMessagePlayerTarget.load()
        this.troubleVoiceMessagePlayerTarget.pause()
        this.troubleVoiceMessagePlayerTarget.play()
        this.troubleVoiceMessagePlayerTarget.classList.remove('d-none')
      })
      .catch(err => {})
  }

  resetAlarmVoiceMessage(event) {
    if (this.hasAlarmVoiceMessageTarget) {
      this.alarmVoiceMessageTarget.value = event.params['defaultMessage']
    }
  }

  resetTroubleVoiceMessage(event) {
    if (this.hasTroubleVoiceMessageTarget) {
      this.troubleVoiceMessageTarget.value = event.params['defaultMessage']
    }
  }

  deleteRule(event) {
    if (this.hasDeletePersonalReportingRuleModalTarget) {
      if (!this.deletePersonalReportingRuleModal) {
        this.deletePersonalReportingRuleModal = new bootstrap.Modal(this.deletePersonalReportingRuleModalTarget, { keyboard: false });
      }
      if (this.deletePersonalReportingRuleModal) {
        this.deletePersonalReportingRuleModalTypeTarget.innerHTML = event.params['type']
        this.deletePersonalReportingRuleModalAddressTarget.innerHTML = event.params['address']
        this.deletePersonalReportingRuleModalDeleteButtonTarget.href = event.params['deleteRoute']
        this.deletePersonalReportingRuleModal.show()
      }
    }
  }

  changeReportingType(event) {
    var selectedType = this.getSelectedOption(event)
    this.setupReportingView(selectedType, true)
  }

  setupReportingView(selectedType, updateEvents) {
    if (selectedType == 0) {
      // EMAIL
      if (this.hasEmailAddressTarget) {
        this.emailAddressTarget.classList.remove('d-none')
        this.emailAddressInputTarget.type = 'email'
        this.emailAddressInputTarget.required = 'required'
      }
      this.resetPushInputs()
      this.resetSmsInputs()
      this.resetVoiceInputs()
      this.resetEmergencyVoiceInputs()
    } else if (selectedType == 1) {
      // PUSH
      this.resetEmailInputs()
      this.resetSmsInputs()
      this.resetVoiceInputs()
      this.resetEmergencyVoiceInputs()
      if (this.hasPushDeviceIndexTarget) {
        this.pushDeviceIndexTarget.classList.remove('d-none')
      }
    } else if (selectedType == 2) {
      // SMS
      this.resetEmailInputs()
      this.resetPushInputs()
      this.resetVoiceInputs()
      this.resetEmergencyVoiceInputs()
      if (this.hasPhoneNumberTarget) {
        const otherController = this.application.getControllerForElementAndIdentifier(this.element, 'phone')
        if (otherController) {
          otherController.initMobilePhone()
        }
        this.mobilePhoneSpanTarget.classList.remove('d-none')
        this.mobilePhoneTarget.classList.remove('d-none')
        this.mobilePhoneTarget.required = 'required'
        this.noteTarget.required = 'required'
        this.phoneNumberTarget.classList.remove('d-none')
        this.smsRuleHelpTarget.classList.remove('d-none')
        if (this.hasSubmitFormTarget) {
          this.submitFormTarget.disabled = true
        }
      }
    } else if (selectedType == 3) {
      // VOICE
      this.resetEmailInputs()
      this.resetPushInputs()
      this.resetSmsInputs()
      this.resetEmergencyVoiceInputs()
      if (this.hasPhoneNumberTarget) {
        const otherController = this.application.getControllerForElementAndIdentifier(this.element, 'phone')
        if (otherController) {
          otherController.initPhone()
        }
        this.phoneSpanTarget.classList.remove('d-none')
        this.phoneNumberTarget.classList.remove('d-none')
        this.phoneTarget.classList.remove('d-none')
        this.noteTarget.required = 'required'
        if (this.hasVoiceRuleHelpTarget) {
          this.voiceRuleHelpTarget.classList.remove('d-none')
          this.voiceRuleOptionsTarget.classList.remove('d-none')
          this.voiceRuleTroubleOptionsTarget.classList.remove('d-none')
        }
        if (this.hasSubmitFormTarget) {
          this.submitFormTarget.disabled = true
        }
      }
    } else if (selectedType == 4) {
      // EMERGENCY VOICE
      this.resetEmailInputs()
      this.resetPushInputs()
      this.resetSmsInputs()
      this.resetVoiceInputs()
      if (this.hasEmergencyPhoneNumberTarget) {
        // emergencyPhoneNumber only exists on the add rule page
        this.emergencyPhoneNumberTarget.classList.remove('d-none')
      }
      if (this.hasVoiceRuleHelpTarget) {
        this.voiceRuleHelpTarget.classList.remove('d-none')
        this.voiceRuleOptionsTarget.classList.remove('d-none')
        this.voiceRuleTroubleOptionsTarget.classList.add('d-none')
      }
    }
    if (updateEvents) {
      this.updateEventChoices(selectedType)
    }
  }

  resetEmailInputs() {
    if (this.hasEmailAddressTarget) {
      this.emailAddressTarget.classList.add('d-none')
      this.emailAddressInputTarget.type = 'text'
      this.emailAddressInputTarget.removeAttribute('required')
    }
  }

  resetPushInputs() {
    if (this.hasPushDeviceIndexTarget) {
      this.pushDeviceIndexTarget.classList.add('d-none')
    }
  }

  resetSmsInputs() {
    if (this.hasSmsRuleHelpTarget) {
      const otherController = this.application.getControllerForElementAndIdentifier(this.element, 'phone')
      if (otherController) {
        otherController.destroyMobilePhone()
      }
      this.mobilePhoneSpanTarget.classList.add('d-none')
      this.mobilePhoneTarget.classList.add('d-none')
      this.mobilePhoneTarget.removeAttribute('required')
      this.noteTarget.removeAttribute('required')
      this.phoneNumberTarget.classList.add('d-none')
      this.smsRuleHelpTarget.classList.add('d-none')
      if (this.hasSubmitFormTarget) {
        this.submitFormTarget.disabled = false
      }
    }
  }

  resetVoiceInputs() {
    if (this.hasPhoneNumberTarget) {
      const otherController = this.application.getControllerForElementAndIdentifier(this.element, 'phone')
      if (otherController) {
        otherController.destroyPhone()
      }
      this.phoneSpanTarget.classList.add('d-none')
      this.phoneNumberTarget.classList.add('d-none')
      this.phoneTarget.classList.add('d-none')
      this.noteTarget.removeAttribute('required')
      if (this.hasVoiceRuleHelpTarget) {
        this.voiceRuleHelpTarget.classList.add('d-none')
      this.voiceRuleOptionsTarget.classList.add('d-none')
      }
      if (this.hasSubmitFormTarget) {
        this.submitFormTarget.disabled = false
      }
    }
  }

  resetEmergencyVoiceInputs() {
    if (this.hasEmergencyPhoneNumberTarget) {
      this.emergencyPhoneNumberTarget.classList.add('d-none')
      if (this.hasVoiceRuleHelpTarget) {
        this.voiceRuleHelpTarget.classList.add('d-none')
        this.voiceRuleOptionsTarget.classList.add('d-none')
      }
    }
  }

  updateEventChoices(selectedType) {
    if ([0, 1].includes(selectedType)) {
      // EMAIL, PUSH
      document.querySelector('input#rule_zone_alarms').checked = true
      document.querySelector('input#rule_zone_alarms').disabled = false
      document.querySelector('input#rule_zone_normal').checked = true
      document.querySelector('input#rule_zone_normal').disabled = false
      document.querySelector('input#rule_zone_bypass').checked = true
      document.querySelector('input#rule_zone_bypass').disabled = false
      document.querySelector('input#rule_zone_trouble').checked = true
      document.querySelector('input#rule_zone_trouble').disabled = false
      document.querySelector('input#rule_arm_and_disarm').checked = true
      document.querySelector('input#rule_arm_and_disarm').disabled = false
      document.querySelector('input#rule_system_troubles').checked = true
      document.querySelector('input#rule_system_troubles').disabled = false
      document.querySelector('input#rule_test_reports').checked = true
      document.querySelector('input#rule_test_reports').disabled = false
      document.querySelector('input#rule_emergency_alarms').checked = true
      document.querySelector('input#rule_emergency_alarms').disabled = false
      document.querySelector('input#rule_access_events').checked = true
      document.querySelector('input#rule_access_events').disabled = false
      if (this.ifobUnlimited) {
        document.querySelector('input#rule_poll_fail').checked = false
        document.querySelector('input#rule_poll_fail').disabled = true
      } else {
        document.querySelector('input#rule_poll_fail').checked = true
        document.querySelector('input#rule_poll_fail').disabled = false
      }
      document.querySelectorAll('div#reportingOptions > table > tbody > tr:not(:nth-child(10))').forEach(element => element.classList.remove('d-none'))
      if (this.hasZonesAdvancedButtonTarget) {
        this.zonesAdvancedButtonTarget.rowSpan = '4'
      }
    } else if (selectedType == 2) {
      // SMS
      document.querySelector('input#rule_zone_alarms').checked = true
      document.querySelector('input#rule_zone_alarms').disabled = false
      document.querySelector('input#rule_zone_normal').checked = false
      document.querySelector('input#rule_zone_normal').disabled = true
      document.querySelector('input#rule_zone_bypass').checked = false
      document.querySelector('input#rule_zone_bypass').disabled = true
      document.querySelector('input#rule_zone_trouble').checked = false
      document.querySelector('input#rule_zone_trouble').disabled = true
      document.querySelector('input#rule_arm_and_disarm').checked = false
      document.querySelector('input#rule_arm_and_disarm').disabled = true
      document.querySelector('input#rule_system_troubles').checked = false
      document.querySelector('input#rule_system_troubles').disabled = true
      document.querySelector('input#rule_test_reports').checked = false
      document.querySelector('input#rule_test_reports').disabled = true
      document.querySelector('input#rule_emergency_alarms').checked = true
      document.querySelector('input#rule_emergency_alarms').disabled = false
      document.querySelector('input#rule_access_events').checked = false
      document.querySelector('input#rule_access_events').disabled = true
      document.querySelector('input#rule_poll_fail').checked = false
      document.querySelector('input#rule_poll_fail').disabled = true
      document.querySelectorAll('div#reportingOptions > table > tbody > tr').forEach(element => element.classList.remove('d-none'))
      document.querySelectorAll('div#reportingOptions > table > tbody > tr:not(:nth-child(1)):not(:nth-child(8))').forEach(element => element.classList.add('d-none'))
      if (this.hasZonesAdvancedButtonTarget) {
        this.zonesAdvancedButtonTarget.rowSpan = '1'
      }
    } else if (selectedType == 3) {
      // VOICE
      document.querySelector('input#rule_zone_alarms').checked = true
      document.querySelector('input#rule_zone_alarms').disabled = false
      document.querySelector('input#rule_zone_normal').checked = false
      document.querySelector('input#rule_zone_normal').disabled = true
      document.querySelector('input#rule_zone_bypass').checked = false
      document.querySelector('input#rule_zone_bypass').disabled = true
      document.querySelector('input#rule_zone_trouble').checked = false
      document.querySelector('input#rule_zone_trouble').disabled = false
      document.querySelector('input#rule_arm_and_disarm').checked = false
      document.querySelector('input#rule_arm_and_disarm').disabled = true
      document.querySelector('input#rule_system_troubles').checked = false
      document.querySelector('input#rule_system_troubles').disabled = false
      document.querySelector('input#rule_test_reports').checked = false
      document.querySelector('input#rule_test_reports').disabled = true
      document.querySelector('input#rule_emergency_alarms').checked = true
      document.querySelector('input#rule_emergency_alarms').disabled = false
      document.querySelector('input#rule_access_events').checked = false
      document.querySelector('input#rule_access_events').disabled = true
      document.querySelector('input#rule_poll_fail').checked = false
      document.querySelector('input#rule_poll_fail').disabled = false
      document.querySelectorAll('div#reportingOptions > table > tbody > tr').forEach(element => element.classList.remove('d-none'))
      document.querySelectorAll('div#reportingOptions > table > tbody > tr:not(:nth-child(1)):not(:nth-child(4)):not(:nth-child(6)):not(:nth-child(8)):not(:nth-child(10))').forEach(element => element.classList.add('d-none'))
      if (this.hasZonesAdvancedButtonTarget) {
        this.zonesAdvancedButtonTarget.rowSpan = '2'
      }
    } else if (selectedType == 4) {
      // EMERGENCY VOICE
      document.querySelector('input#rule_zone_alarms').checked = true
      document.querySelector('input#rule_zone_alarms').disabled = false
      document.querySelector('input#rule_zone_normal').checked = false
      document.querySelector('input#rule_zone_normal').disabled = true
      document.querySelector('input#rule_zone_bypass').checked = false
      document.querySelector('input#rule_zone_bypass').disabled = true
      document.querySelector('input#rule_zone_trouble').checked = false
      document.querySelector('input#rule_zone_trouble').disabled = true
      document.querySelector('input#rule_arm_and_disarm').checked = false
      document.querySelector('input#rule_arm_and_disarm').disabled = true
      document.querySelector('input#rule_system_troubles').checked = false
      document.querySelector('input#rule_system_troubles').disabled = true
      document.querySelector('input#rule_test_reports').checked = false
      document.querySelector('input#rule_test_reports').disabled = true
      document.querySelector('input#rule_emergency_alarms').checked = true
      document.querySelector('input#rule_emergency_alarms').disabled = false
      document.querySelector('input#rule_access_events').checked = false
      document.querySelector('input#rule_access_events').disabled = true
      document.querySelector('input#rule_poll_fail').checked = false
      document.querySelector('input#rule_poll_fail').disabled = true
      document.querySelectorAll('div#reportingOptions > table > tbody > tr').forEach(element => element.classList.remove('d-none'))
      document.querySelectorAll('div#reportingOptions > table > tbody > tr:not(:nth-child(1)):not(:nth-child(8))').forEach(element => element.classList.add('d-none'))
      if (this.hasZonesAdvancedButtonTarget) {
        this.zonesAdvancedButtonTarget.rowSpan = '1'
      }
    }
  }

  updateEmergencyVoiceAddress(event) {
    var newAddress = this.getSelectedOption(event)
    if (!newAddress.toString().startsWith('+')) {
      newAddress = '+' + newAddress
    }
    this.emergencyVoiceCallAddressTarget.value = newAddress
  }

  resetFilterRanges(event) {
    document.querySelectorAll('[resettableText=true]').forEach(element => element.value = '')
    document.querySelectorAll('[resettableCheckbox=true]').forEach(element => element.checked = true)
  }

  getSelectedOption(event) {
    return Number([...event.target.selectedOptions].map(option => option.value)[0])
  }
}
