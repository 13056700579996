import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="camera-configuration"
export default class extends Controller {
  static targets = [
    "masterUserCheckbox"
  ];

  connect() {
  }

  cameraMasterCheckboxCheck(event) {
    this.masterUserCheckboxTargets.forEach((masterUserCheckbox) => {

      if (masterUserCheckbox !== event.target){
        masterUserCheckbox.checked = false;
      }

    });
  }
}
